function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

async function registerServiceWorker() {
  if ("serviceWorker" in navigator && "PushManager" in window) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/service-worker.js",
      )
      console.log("Service Worker registered successfully:", registration)

      await navigator.serviceWorker.ready
      console.log("Service Worker is active")

      return registration
    } catch (error) {
      console.error("Service Worker registration failed:", error)
      throw error
    }
  } else {
    console.warn("Push notifications are not supported in this browser")
    throw new Error("Push notifications not supported")
  }
}

export function isPushSupported() {
  return "serviceWorker" in navigator && "PushManager" in window
}

export async function showNotification(webPushData) {
  if (!isPushSupported() || !webPushData?.message) {
    return
  }

  try {
    const registration = await navigator.serviceWorker.ready
    await registration.showNotification(webPushData.message.title, {
      body: webPushData.message.body,
      icon: new URL("/favicons/favicon-32x32.png", window.location.href).href,
      tag: `notification-${webPushData.url}`,
      data: {url: webPushData.url},
    })
  } catch (error) {
    console.error("Failed to show notification:", error)
  }
}

export async function initializePushNotifications() {
  if (!isPushNotificationSupported()) {
    console.warn("Push notifications are not supported in this browser")
    return false
  }

  try {
    const permission = await Notification.requestPermission()
    if (permission !== "granted") {
      return false
    }

    const registration = await registerServiceWorker()
    return !!registration
  } catch (error) {
    console.error("Failed to initialize push notifications:", error)
    return false
  }
}

export function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window
}
