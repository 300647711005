import React, {useRef, useEffect} from "react"
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Feed,
  FeedContent,
  FeedEvent,
  FeedSummary,
} from "semantic-ui-react"
import useNotifications from "../hooks/useNotifications/useNotifications"
import {RTINotification} from "../types/notification"
import Label from "../Label"

type NotificationFeedProps = {
  onClose: () => void
  initNotifications: RTINotification[]
}

const NotificationFeed = (props: NotificationFeedProps) => {
  const {initNotifications, onClose} = props

  const {
    borderLeftColor,
    degreeFontColor,
    degreeColor,
    getFontColor,
    getGroupedAndSortedIndicators,
    isStateThree,
    labelColor,
    notifications,
    openReviewEpisode,
  } = useNotifications(initNotifications)
  const logRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        logRef.current &&
        !logRef.current.contains(event.target) &&
        !event.target.closest(".notification-container")
      ) {
        onClose()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [onClose])
  // Will divide this component to two seperate one as PICQ and RISQ
  return (
    <div ref={logRef}>
      {notifications.map(episodeNotification => {
        const groupedIndicators = getGroupedAndSortedIndicators(
          episodeNotification.indicators || [],
        )

        return (
          <Card key={episodeNotification.id} className="feed-card ">
            <CardContent>
              <CardHeader className="episode-header">
                {`Episode Processed${
                  (!groupedIndicators.PICQ?.length && !groupedIndicators.RISQ?.length)
                    ? ' - No Indicators Triggered'
                    : ''
                }`}
              </CardHeader>
              <div>
                <Feed>
                  <FeedEvent>
                    <FeedContent>
                      <FeedSummary>
                        <div className="indicators">
                          <span>{episodeNotification.ueid}</span>
                          <span className="text-color">
                            {new Date(
                              episodeNotification.time,
                            ).toLocaleTimeString("en-US", {
                              hour12: true,
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </span>
                        </div>

                        <Divider
                          style={{marginTop: "auto", marginBottom: "7px"}}
                        />

                        {/* PICQ Indicators */}
                        {groupedIndicators.PICQ.length > 0 && (
                          <div className="indicators">
                            <span className="picq-risq-title">Picq</span>
                            {!groupedIndicators.PICQ.every((indicator: any) =>
                              isStateThree(indicator),
                            ) && (
                              <a
                                onClick={() =>
                                  openReviewEpisode(
                                    episodeNotification.episode_id,
                                    "PICQ",
                                  )
                                }
                                className="review-title"
                              >
                                Review
                              </a>
                            )}
                          </div>
                        )}
                        {groupedIndicators.PICQ.map((indicator, idx) => {
                          return (
                            <div key={idx}>
                              <div
                                className={`indicator ${
                                  indicator.reviewed ? "reviewed" : ""
                                }`}
                                style={{
                                  "--border-color": borderLeftColor(indicator),
                                  position: "relative",
                                  backgroundColor: degreeColor(indicator),
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                }}
                              >
                                <span
                                  style={{
                                    color: degreeFontColor(indicator),
                                    flexShrink: 0,
                                    fontWeight: "700",
                                    width: "50px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {indicator.degree}
                                </span>
                                <span
                                  style={{
                                    color: degreeFontColor(indicator),
                                    flex: "1",
                                    marginRight: "auto",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  Indicator: {indicator.indicator_identifier}
                                </span>
                                <Label
                                  backgroundColor={labelColor(indicator)}
                                  borderRadius="15px"
                                  color={getFontColor(indicator)}
                                  size="large"
                                  width="auto"
                                >
                                  {indicator.status}
                                </Label>
                              </div>
                            </div>
                          )
                        })}
                        {groupedIndicators.PICQ.length > 0 && (
                          <Divider style={{margin: "auto"}} />
                        )}

                        {/* RISQ Indicators */}
                        {groupedIndicators.RISQ.length > 0 && (
                          <div className="indicators">
                            <span className="picq-risq-title">Risq</span>
                            {!groupedIndicators.RISQ.every((indicator: any) =>
                              isStateThree(indicator),
                            ) && (
                              <a
                                onClick={() =>
                                  openReviewEpisode(
                                    episodeNotification.episode_id,
                                    "RISQ",
                                  )
                                }
                                className="review-title"
                              >
                                Review
                              </a>
                            )}
                          </div>
                        )}
                        {groupedIndicators.RISQ.map((indicator, idx) => {
                          return (
                            <div key={idx}>
                              <div
                                className={`indicator ${
                                  indicator.reviewed ? "reviewed" : ""
                                }`}
                                style={{
                                  "--border-color": borderLeftColor(indicator),
                                  position: "relative",
                                  backgroundColor: degreeColor(indicator),
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "5px",
                                  overflow: "hidden",
                                }}
                              >
                                <span
                                  style={{
                                    color: degreeFontColor(indicator),
                                    flexShrink: 0,
                                    fontWeight: "700",
                                    width: "50px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {indicator.degree}
                                </span>
                                <span
                                  style={{
                                    color: degreeFontColor(indicator),
                                    flex: "1",
                                    marginRight: "auto",
                                    textAlign: "left",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  Indicator: {indicator.indicator_identifier}
                                </span>
                                <Label
                                  backgroundColor={labelColor(indicator)}
                                  borderRadius="15px"
                                  color={getFontColor(indicator)}
                                  size="large"
                                  width="auto"
                                >
                                  {indicator.status}
                                </Label>
                              </div>
                            </div>
                          )
                        })}
                      </FeedSummary>
                    </FeedContent>
                  </FeedEvent>
                </Feed>
              </div>
            </CardContent>
          </Card>
        )
      })}
    </div>
  )
}

export default NotificationFeed
