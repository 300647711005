$(".real_time_validation_errors.index").ready(function () {
  $("#real_time_validation_list_table").DataTable({
    order: [[3, "desc"]],
    searching: true,
    lengthChange: true,
    info: true,
    paging: true,
    columnDefs: [
      {render: null, targets: "_all"},
      {className: "datatable-wrap", targets: "_all"},
      {orderable: false, targets: [6]},
    ],
    dom: '<"toolbar">flrtip',
    language: {
      search: "",
      searchPlaceholder: "Search...",
      sSearch: '<i class="search icon"></i>',
    },
  })
})
