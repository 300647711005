import {optionsManagerService} from "../services/options-manager.service"
import {selectsStorageService} from "../services/selects-storage.service"
import {paramsDecorator} from "../../common/helpers"

export var dateRangeComponent = (function () {
  var _currentDate

  $(document).ready(function () {
    function updateFieldsVisibility() {
      const selectedOption = $("#indicator_time_period_select").val()

      if (["7", "30", "90", "1"].includes(selectedOption)) {
        $("#tag_and_date_fields").show()
      }

      if (selectedOption === "1") {
        $("#tag_and_date_fields .dropdown").addClass("disabled")
        $("#date_filed_type_select").dropdown("set selected", "Coding date")
      } else {
        $("#tag_and_date_fields .dropdown").removeClass("disabled")
      }

      if (selectedOption === "By Extract") {
        $(".tag-select-dropdown").show()
      } else {
        $(".tag-select-dropdown").hide()
      }
    }

    updateFieldsVisibility()

    $("#indicator_time_period_select").change(updateFieldsVisibility)

    initLimitedDatePickers()
  })

  const getLastFinancialYearStartDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = today.getMonth()

    if (month < 9) {
      return new Date(year - 1, 6, 1)
    } else {
      return new Date(year, 6, 1)
    }
  }

  const initLimitedDatePickers = () => {
    const lastFinancialYearStart = getLastFinancialYearStartDate()

    $("#limitedDatepickerSelectFrom").datepicker({
      dateFormat: "yy-mm-dd",
      changeMonth: true,
      changeYear: true,
      minDate: lastFinancialYearStart,
      onSelect: function (selectedDate) {
        $("#limitedDatepickerSelectTo").datepicker(
          "option",
          "minDate",
          selectedDate,
        )
      },
    })

    $("#limitedDatepickerSelectTo").datepicker({
      dateFormat: "yy-mm-dd",
      changeMonth: true,
      changeYear: true,
      onSelect: function (selectedDate) {
        $("#limitedDatepickerSelectFrom").datepicker(
          "option",
          "maxDate",
          selectedDate,
        )
      },
    })

    const today = new Date().toISOString().substring(0, 10)
    $("#limitedDatepickerSelectFrom").datepicker("option", "maxDate", today)
    $("#limitedDatepickerSelectTo").datepicker("option", "maxDate", today)
  }

  function _disableDatepicker() {
    $("#datepickerSelectFrom").val("")
    $("#datepickerSelectTo").val("")

    selectsStorageService.removeSelectFromStorage("datepickerSelectFrom")
    selectsStorageService.removeSelectFromStorage("datepickerSelectTo")

    _currentDate = undefined
    setDatePickersLimits(
      $("#datepickerSelectInput input"),
      $("#datepickerSelectFrom"),
      $("#datepickerSelectTo"),
    )
  }

  var setDatePickersLimits = function (
    $datePickerInputs,
    $datePickerFrom,
    $datePickerTo,
  ) {
    if (
      $datePickerInputs === undefined ||
      $datePickerInputs.length === 0 ||
      $datePickerFrom === undefined ||
      $datePickerFrom.length === 0 ||
      $datePickerTo === undefined ||
      $datePickerTo.length === 0
    ) {
      console.warn("setDatePickersLimits() missed input", arguments)
      return false
    }

    if (_currentDate === undefined) {
      _currentDate = new Date().toISOString().substring(0, 10)

      $datePickerFrom.datepicker("option", "maxDate", _currentDate)
      $datePickerTo.datepicker("option", "maxDate", _currentDate)
    }

    $datePickerInputs.change(function () {
      $datePickerFrom.datepicker("option", "maxDate", $datePickerTo.val())
      $datePickerTo.datepicker("option", "minDate", $datePickerFrom.val())
    })
  }

  var _processDatepick = function () {
    setDatePickersLimits(
      $("#datepickerSelectInput input"),
      $("#datepickerSelectFrom"),
      $("#datepickerSelectTo"),
    )
  }

  var _fillDatepickerValues = function () {
    optionsManagerService.setActiveSavedOption(
      "datepickerSelectFrom",
      "datepickerSelectFrom",
      "",
    )
    optionsManagerService.setActiveSavedOption(
      "datepickerSelectTo",
      "datepickerSelectTo",
      "",
    )

    for (var i = 0; i < $("#datepickerSelectInput input").length; i++) {
      var inputsCount = 2
      var emptyCount = 0

      if ($($("#datepickerSelectInput input")[i])[0] == "") {
        emptyCount++
      }

      if (emptyCount != 0 && emptyCount != inputsCount) {
        _disableDatepicker()
      }
    }

    setDatePickersLimits(
      $("#datepickerSelectInput input"),
      $("#datepickerSelectFrom"),
      $("#datepickerSelectTo"),
    )
  }

  var initDatepicker = function () {
    _fillDatepickerValues()

    var $datepickerSelects = $("#datepickerSelectTo, #datepickerSelectFrom")

    $datepickerSelects.on("focusin", function (e) {
      var targetId = e.currentTarget.id
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value
    })

    $datepickerSelects.on("change", function (e) {
      var targetId = e.currentTarget.id

      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        e.currentTarget.value
      ) {
        optionsManagerService.selectsStateStorage[targetId].newValue =
          e.currentTarget.value
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue,
        )
      }

      if (
        ($("#datepickerSelectFrom").val() === "" &&
          $("#datepickerSelectTo").val() === "") ||
        ($("#datepickerSelectFrom").val() !== "" &&
          $("#datepickerSelectTo").val() !== "")
      ) {
        setTimeout(_processDatepick(), 0)
      }
    })

    // Disable Auto complete on time fields
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password#allowing_autocomplete
    // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill?page=1&tab=votes#tab-top
    $(".date-picker")
      .attr("autocomplete", "off")
      .datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      })
      .on("changeDate", function (e) {
        return $(this).datepicker("hide")
      })
      .keydown(false)
      .keyup(function (e) {
        if (e.keyCode == 8 || e.keyCode == 46) {
          $.datepicker._clearDate(this)
        }
      })
  }

  var showHideSelectsByTimePeriod = function () {
    switch ($("#indicator_time_period_select").val()) {
      case "Date range":
        $("#dateFieldTypeSelectWrapper").show()
        $("#datepickerSelectInput").show()
        $("#datepickerSelectInputFrom").show()
        $("#datepickerSelectInputTo").show()
        $("#tag_select_dropdown").hide()
        setDatePickersLimits(
          $("#datepickerSelectInput input"),
          $("#datepickerSelectFrom"),
          $("#datepickerSelectTo"),
        )
        break
      case "By Extract":
        $("#tag_select_dropdown").show()
        $("#datepickerSelectInput").hide()
        $("#datepickerSelectInputFrom").hide()
        $("#datepickerSelectInputTo").hide()
        $("#dateFieldTypeSelectWrapper").hide()
        _disableDatepicker()
        break
      default:
        $("#dateFieldTypeSelectWrapper").show()
        $("#datepickerSelectInputFrom").hide()
        $("#datepickerSelectInputTo").hide()
        $("#datepickerSelectInput").hide()
        $("#tag_select_dropdown").hide()
        _disableDatepicker()
        break
    }
  }

  var changeParamsByTimePeriod = function (params) {
    var returnParams
    var paramsToCheck
    var dateTypeString

    if (params.dos_time_period !== undefined) {
      paramsToCheck = params.dos_time_period
      dateTypeString = "dos"
    } else if (params.coding_date_time_period !== undefined) {
      paramsToCheck = params.coding_date_time_period
      dateTypeString = "coding_date"
    }

    switch (paramsToCheck) {
      case "Date range":
        function changeParamsByDateType(dateTypeString) {
          var timePeriodParam = dateTypeString + "_time_period"
          var fromParam = dateTypeString + "_from"
          var toParam = dateTypeString + "_to"

          paramsDecorator.setParamsObj(params)
          paramsDecorator.removeKeys([timePeriodParam, "tag"])

          var datepickerFromVal = $("#datepickerSelectFrom").val()
          var datepickerToVal = $("#datepickerSelectTo").val()
          if (datepickerFromVal !== "" && datepickerToVal !== "") {
            var obj = {}
            obj[fromParam] = datepickerFromVal
            obj[toParam] = datepickerToVal
            paramsDecorator.addKeys(obj)
          } else {
            var obj = {
              empty_date_range: true,
            }
            paramsDecorator.addKeys(obj)
          }
        }

        // TODO: write UT!!!!!!!!!!!!
        changeParamsByDateType(dateTypeString)
        returnParams = paramsDecorator.getParamsObj()
        break
      case "By Extract":
        paramsDecorator.setParamsObj(params)
        paramsDecorator.removeKeys([
          "dos_time_period",
          "coding_date_time_period",
          "date_field_type",
        ])
        var dateFieldDefaultValue = {date_field_type: "dos"}
        paramsDecorator.addKeys(dateFieldDefaultValue)
        returnParams = paramsDecorator.getParamsObj()
        break
      default:
        paramsDecorator.setParamsObj(params)
        paramsDecorator.removeKeys(["tag"])
        returnParams = paramsDecorator.getParamsObj()
        break
    }

    return returnParams
  }

  var checkTimePeriodParams = function (params) {
    var paramsToCheck

    //TODO: write UT!!!
    if (params.dos_time_period !== undefined) {
      paramsToCheck = params.dos_time_period
    } else if (params.coding_date_time_period !== undefined) {
      paramsToCheck = params.coding_date_time_period
    }

    if (paramsToCheck !== undefined && paramsToCheck === "Date range") {
      if (
        ($("#datepickerSelectFrom").val() === "" &&
          $("#datepickerSelectTo").val() === "") ||
        ($("#datepickerSelectFrom").val() !== "" &&
          $("#datepickerSelectTo").val() !== "")
      ) {
        return true
      } else {
        _disableDatepicker()
        return false
      }
    } else {
      return true
    }
  }

  // Disable Auto complete on time fields
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/password#allowing_autocomplete
  // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill?page=1&tab=votes#tab-top
  var initSimpleDatePicker = function () {
    $(".date-picker")
      .attr("autocomplete", "off")
      .datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        dayNamesMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      })
      .on("changeDate", function (e) {
        return $(this).datepicker("hide")
      })
      .keydown(false)
      .keyup(function (e) {
        if (e.keyCode == 8 || e.keyCode == 46) {
          $.datepicker._clearDate(this)
        }
      })
  }

  return {
    initDatepicker: initDatepicker,
    initSimpleDatePicker: initSimpleDatePicker,
    setDatePickersLimits: setDatePickersLimits,
    checkTimePeriodParams: checkTimePeriodParams,
    changeParamsByTimePeriod: changeParamsByTimePeriod,
    showHideSelectsByTimePeriod: showHideSelectsByTimePeriod,
  }
})()
