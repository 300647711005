import React from "react"
import consumer from "./consumer"
import Notification from "../legacy/components/Notification"
import {showNotification} from "../legacy/push_notifications"

let notificationsSubscription

function initializeNotificationsChannel(profileId) {
  notificationsSubscription = consumer.subscriptions.create(
    {channel: "NotificationsChannel", profile_id: profileId},
    {
      initialized() {
        window.rtiRoot.render(<Notification viewed={this.viewed} />)
      },

      async received(message) {
        const notificationContainer = document.querySelector(
          "[data-react-component='Notification']",
        )

        if (notificationContainer) {
          const event = new CustomEvent("newMessage", {detail: {message}})
          notificationContainer.dispatchEvent(event)

          if (message.web_push_data) {
            await showNotification(message.web_push_data)
          }
        }
      },

      viewed() {
        notificationsSubscription.perform("viewed")
      },
    },
  )
}

export {initializeNotificationsChannel}
