import React, { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react'
import LogBlock from './LogBlock'
import { RTINotification } from '../types/notification'
import { filterNotificationsByToday } from '../hooks/useNotifications/useNotifications'

type NotificationProps = {
  viewed: () => void
}

const Notification: React.FC = (props: NotificationProps) => {
  const { viewed } = props
  const [notifications, setNotifications] = useState<RTINotification[]>([])
  const [activities, setActivities] = useState<RTINotification[]>([])
  const [count, setCount] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    // Run once on initial load to get the notifications from the backend
    $.ajax({
      url: "/api/live/v1/notifications/all.json",
      type: "GET",
      success: function(result) {
        setActivities(filterNotificationsByToday(result))
      },
      error: function(error) {
        console.log(error)
      }
    })

    $.ajax({
      url: "/api/live/v1/notifications/indicators.json",
      type: "GET",
      success: function(result) {
        let notification_count = filterNotificationsByToday(result).reduce((acc, curr) => acc + curr.indicators.filter((x) => !x.viewed && x.status == 'New').length, 0)

        setNotifications(filterNotificationsByToday(result))
        setCount(notification_count)
      },
      error: function(error) {
        console.log(error)
      }
    })

  }, [])

  useEffect(() => {
    const notificationContainer = document.querySelector("[data-react-component='Notification']")

    const handleNewMessage = (event: CustomEvent<{ message: { all: RTINotification[], indicators: RTINotification[] } }>) => {
      const newMessage = event.detail.message

      setActivities(filterNotificationsByToday(newMessage.all))

      const notification_count = filterNotificationsByToday(newMessage.indicators).reduce((acc, curr) => acc + curr.indicators.filter((x) => !x.viewed && x.status == 'New').length, 0)
      setCount(notification_count)

      setNotifications(filterNotificationsByToday(newMessage.indicators))
    }

    notificationContainer?.addEventListener('newMessage', handleNewMessage)

    return () => {
      notificationContainer?.removeEventListener('newMessage', handleNewMessage)
    }
  }, [notifications, count])

  const handleBellClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsOpen((prev) => !prev)
    if (!isOpen) {
      setCount(0)
      viewed()
    }
  }

  const closeNotificationFeed = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <div className="notification-wrapper">
      <div className="notification-container">
        <Icon name='bell outline' onClick={handleBellClick} size='large' style={{ cursor: 'pointer' }} />
        {count > 0 && <span className="notification-count">{count}</span>}
        {isOpen && <LogBlock notifications={notifications} activities={activities} onClose={closeNotificationFeed} />}
      </div>
    </div>
  )
}

export default Notification
