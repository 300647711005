import ieHelpers from "./common/ie-helpers"
import SemanticUiFixes from "./common/semantic-ui-fixes"
import DataTablePlugins from "./common/data-table-plugins"
import authenticationService from "./common/services/authentication.service"
import {timePeriodSelectComponent} from "./reports/components/indicator_time_period_select_component"
import {calculateAndSetSizeElementById} from "./reports/components/loading_cirlce_component"
import {initVisualEffects, initDataTable} from "./common/helpers"

// This needs to be before DOMContentLoaded, otherwise requests that
// get called on the same event may be run before timezone is set
$.ajaxSetup({
  beforeSend: function (xhr)
  {
    let userTimeZone = "UTC"
    if ("Intl" in window && Intl.DateTimeFormat) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC"
    } else {
      const offset = new Date().getTimezoneOffset() / -60
      userTimeZone = `${offset >= 0 ? "+" : "-"}${offset}`
    }
    if (userTimeZone === undefined) userTimeZone = "UTC"
    xhr.setRequestHeader("X-TimeZone", userTimeZone)
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var initIeHelpers = new ieHelpers()
  initIeHelpers.checkLocationShowModal()

  var semanticUiFixes = new SemanticUiFixes()
  semanticUiFixes.initMultipleSelectHandler()

  var dataTablePlugins = new DataTablePlugins()
  dataTablePlugins.initDateSortPlugin()
  dataTablePlugins.initStringifiedAlphaNumSortPlugin()

  // TODO: refactor this or move to auth service
  // TODO: clean on click on impersonate
  var _isNewSessionPage = window.location.pathname == "/user_session/new"
  var _isSelectRolePage =
    window.location.pathname ==
    "/users/" + $("[data-user-id]").data("userId") + "/profiles"
  if (_isNewSessionPage || _isSelectRolePage) return

  if (
    authenticationService.checkIsUserImpersonated() ||
    ($("[data-user-role]").data("userRole") !=
      localStorage.getItem("lastRole") &&
      localStorage.getItem("lastRole") !== null)
  ) {
    localStorage.removeItem("lastRole")
    authenticationService.cleanSavedFromStorage()
  }

  $("#cancelImpersonateBtn").on("click", function () {
    authenticationService.cleanSavedFromStorage()
  })

  toastr.options.positionClass = "toast-bottom-right"
  toastr.options.preventDuplicates = true

  $("[data-trigger='dropdown']").dropdown()

  // Custom support for select with optgroup with Semantic UI
  $(".ui.dropdown")
    .has("optgroup")
    .each(function () {
      var menu = $("<div/>").addClass("menu")
      $(this)
        .find("select")
        .children()
        .each(function () {
          if ($(this).is("option")) {
            return menu.append(
              '<div class="item' +
                (this.selected ? " active selected" : "") +
                (this.disabled ? " disabled" : "") +
                '" data-value="' +
                this.value +
                '">' +
                (this.label || this.innerHTML) +
                "</div>",
            )
          }
          if ($(this).is("optgroup")) {
            var isDisabled = this.disabled || false
            menu.append(
              '<div class="sub header' +
                (isDisabled ? " item disabled" : "") +
                '">' +
                this.label +
                "</div>",
            )
            // $menu.append('<div class="divider"></div>');
            $(this)
              .children()
              .each(function () {
                return menu.append(
                  '<div class="item' +
                    (this.selected ? " active selected" : "") +
                    (isDisabled || this.disabled ? " disabled" : "") +
                    '" data-value="' +
                    this.value +
                    '">' +
                    "&emsp;" +
                    (this.label || this.innerHTML) +
                    "</div>",
                )
              })
            return menu
          }
        })
      return $(this).find(".menu").html(menu.html())
    })

  $("#right_menu").dropdown({on: "hover"})

  $(".menu-nav").click(function (e) {
    e.preventDefault()

    const navType = $(this).attr("id").replace("_nav", "")

    if ($("#" + navType + "_sub_menu").is(":visible")) {
      $(this).children().last().toggleClass("down left")
    } else {
      $(this).children().last().toggleClass("left down")
    }
    $($("#" + navType + "_sub_menu")).slideToggle()
  })

  // cause the service.js is excess
  initDataTable({
    tableId: "#services_list_table",
    orderVal: 1,
    targetsVal: [0],
  })

  initDataTable({tableId: "#picq_field_table", orderVal: 2, targetsVal: [0]})

  initDataTable({tableId: "#AnalyserTable"})
  initDataTable({tableId: "#ReportingTable"})
  initDataTable({tableId: "#UnknownTable"})

  /* common things */
  if ($("#pagePopup") !== undefined) {
    $("#pagePopup").popup({
      popup: $("#pagePopupContent"),
      on: "hover",
      position: "bottom",
      delay: {
        show: 100,
        hide: 100,
      },
    })
  }
  // common things

  // instead of remaking markup for "non edit" and "edit" pages - here is "edit page" header with title is generated dynamically
  if ($(".edit-page") !== undefined) {
    $(".edit-page").width($(".pusher").width())
    $(".edit-page .header").width($(".centered-content").width())
  }

  timePeriodSelectComponent.checkIsTimePeriodByExtract()
  calculateAndSetSizeElementById("progressBlockWrapper")
  $(window).resize(function () {
    calculateAndSetSizeElementById("progressBlockWrapper")
  })

  initVisualEffects()
})
