import * as React from "react"
import {useMemo} from "react"
import {CardContent, CardHeader, Icon, Tab} from "semantic-ui-react"
import NotificationFeed from "./NotificationFeed"
import {RTINotification} from "../types/notification"

type LogBlockProps = {
  onClose: () => void
  notifications: RTINotification[]
  activities: RTINotification[]
}

const LogBlock = (props: LogBlockProps) => {
  const {notifications, activities, onClose} = props

  const panes = useMemo(
    () => [
      {
        menuItem: "Indicators",
        render: () => (
          <Tab.Pane key="indicators" attached={false} className="rti-segment">
            <NotificationFeed
              initNotifications={notifications}
              onClose={onClose}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "All Activity",
        render: () => (
          <Tab.Pane key="all-activity" attached={false} className="rti-segment">
            <NotificationFeed
              initNotifications={activities}
              onClose={onClose}
            />
          </Tab.Pane>
        ),
      },
    ],
    [notifications, activities, onClose],
  )

  return (
    <CardContent className="notification-feed">
      <CardHeader className="feed-header">
        <div className="feed-header-title">Notification Log</div>
        <div style={{color: '#1C1B1B' }}>
          <div
            className="notification-close"
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              marginRight: 0,
              fontSize: '20px'
            }}
            onClick={onClose}
          >
            X
          </div>
        </div>
      </CardHeader>

      <Tab
        defaultActiveIndex={0}
        menu={{secondary: true, pointing: true}}
        panes={panes}
      />
    </CardContent>
  )
}

export default LogBlock
