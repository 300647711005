import React from 'react'

interface LabelProps {
  children: React.ReactNode
  color?: string
  backgroundColor?: string
  className?: string
  htmlFor?: string
  basic?: boolean
  pointing?: 'left' | 'right' | 'above' | 'below'
  size?: 'mini' | 'tiny' | 'small' | 'medium' | 'large' | 'big'
  borderRadius?: string
  width?: string
}

const Label = ({
  children,
  color = '#000000',
  backgroundColor = '#E8E8E8',
  className = '',
  htmlFor,
  basic = false,
  pointing,
  size = 'medium',
  borderRadius = '3px',
  width
}: LabelProps) => {
  // Validate hex color codes
  const isValidHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  const labelColor = isValidHex.test(color) ? color : '#000000'
  const labelBackgroundColor = isValidHex.test(backgroundColor) ? backgroundColor : '#E8E8E8'

  const getSizeStyles = (size: LabelProps['size']): React.CSSProperties => {
    const sizes = {
      mini: { fontSize: '10px', padding: '2px 4px' },
      tiny: { fontSize: '11px', padding: '3px 6px' },
      small: { fontSize: '12px', padding: '4px 8px' },
      medium: { fontSize: '13px', padding: '5px 10px' },
      large: { fontSize: '14px', padding: '6px 12px' },
      big: { fontSize: '16px', padding: '7px 14px' },
      huge: { fontSize: '18px', padding: '8px 16px' },
      massive: { fontSize: '20px', padding: '9px 18px' },
    }
    return sizes[size] || sizes.medium
  }

  const getPointingStyles = (): React.CSSProperties => {
    if (!pointing) return {}

    switch (pointing) {
      case 'left':
        return { marginLeft: '8px' }
      case 'right':
        return { marginRight: '8px' }
      case 'above':
        return { marginTop: '8px' }
      case 'below':
        return { marginBottom: '8px' }
      default:
        return {}
    }
  }

  const defaultStyles: React.CSSProperties = {
    display: 'inline-block',
    lineHeight: '1',
    verticalAlign: 'baseline',
    margin: '0 0.25em',
    backgroundColor: basic ? 'transparent' : labelBackgroundColor,
    color: labelColor,
    fontWeight: 500,
    transition: 'background 0.2s ease',
    border: basic ? `1px solid ${labelBackgroundColor}` : 'none',
    position: 'relative',
    ...getSizeStyles(size),
    ...getPointingStyles(),
  }

  // Custom styles from props
  const customStyles: React.CSSProperties = {
    borderRadius,
    ...(width && { width }),
  }

  return (
    <label
      htmlFor={htmlFor}
      className={className}
      style={{ ...defaultStyles, ...customStyles }}
    >
      {children}
    </label>
  )
}

export default Label