import { useState } from 'react'
import { RTINotification, Indicator } from '../../types/notification'


const groupNotificationsByEpisode = (notifications: RTINotification[]):{ [episodeId: number]: RTINotification[]} => {
  return notifications.reduce((acc: {}, notification: RTINotification) => {
    const episodeId = notification.id

    if (!acc[episodeId]) {
      acc[episodeId] = []
    }
    acc[episodeId].push(notification)
    return acc
  }, {})
}

const groupAndSortIndicators = (indicators: Indicator[]) => {
  const grouped = { PICQ: [], RISQ: [] }

  indicators.forEach((indicator: { degree: string }) => {
    if (indicator.degree === 'HAC') {
      grouped.RISQ.push(indicator)
    } else if (['F', 'W1', 'W2'].includes(indicator.degree)) {
      grouped.PICQ.push(indicator)
    }
  })

  const sortByDegreeAndIndicatorNumber = (a: { indicator_identifier: string; degree: string }, b: { indicator_identifier: string; degree: string }) => {
    const aIndicatorNum = parseInt(a.indicator_identifier, 10)
    const bIndicatorNum = parseInt(b.indicator_identifier, 10)

    if (isNaN(aIndicatorNum) || isNaN(bIndicatorNum)) {
      console.warn("Invalid indicator_identifier found during sorting", a, b)
      return 0
    }

    if (a.degree === b.degree) {
      return aIndicatorNum - bIndicatorNum
    }
    return a.degree.localeCompare(b.degree)
  }

  grouped.PICQ.sort(sortByDegreeAndIndicatorNumber)
  grouped.RISQ.sort(sortByDegreeAndIndicatorNumber)

  return grouped;
}

export const filterNotificationsByToday = (notifications: RTINotification[]) => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const tomorrow = new Date(today)
  tomorrow.setDate(today.getDate() + 1)

  return notifications.filter((notification: RTINotification) => {
    const notificationDate = new Date(notification.time)
    return notificationDate >= today && notificationDate < tomorrow
  })
}

const isStateThree = (indicator: any) => {
  const stateThreeStatuses = ["Justified", "Fixed", "Resolved"];
  return stateThreeStatuses.includes(indicator.status)
}

const degreeColor = (indicator: Indicator) => {
  if (isStateThree(indicator)) {
    return "#67B640"
  }

  if (indicator.reviewed) {
    return "#F1EDEC"
  }

  switch (indicator.degree) {
    case 'HAC':
    case 'F':
      return indicator.status === 'New' ? "#E71421" : "#F1EDEC"
    case 'W1':
      return indicator.status === 'New' ? "#FF713E" : "#F1EDEC"
    case 'W2':
      return indicator.status === 'New' ? "#FFC04C" : "#F1EDEC"
    default:
      return "#F1EDEC"
  }
}

const labelColor = (indicator: Indicator) => {
  if (isStateThree(indicator)) {
    return "#296C00"
  }

  if (indicator.reviewed) {
    switch (indicator.degree) {
      case 'F':
        return "#E71421"
      case 'W1':
        return "#FF713E"
      case 'W2':
        return "#FFC04C"
      case 'HAC':
        return "#E71421"
      default:
        return "#E71421"
    }
  }

  switch (indicator.status) {
    case 'Justified':
    case 'Resolved':
    case 'Fixed':
      return "#296C00"
    default:
      switch (indicator.degree) {
        case 'F':
          return "#A50010"
        case 'W1':
          return "#AB3500"
        case 'W2':
          return "#FFD488"
        case 'HAC':
          return "#A50010"
        default:
          return "#A50010"
      }
  }
}

const borderLeftColor = (indicator: Indicator) => {
  if (isStateThree(indicator)) {
    return "none"
  }

  if (indicator.reviewed) {
    switch (indicator.degree) {
      case 'HAC':
      case 'F':
        return "#E71421"
      case 'W1':
        return "#FF713E"
      case 'W2':
        return "#FFC04C"
      default:
        return "#E71421"
    }
  }

  return 'none'
}

const getFontColor = (indicator: Indicator) => {
  if (isStateThree(indicator)) {
    return "#FFFFFF";
  }

  if (!indicator.reviewed) {
    if (indicator.degree === 'W2') {
      return "#000000";
    }
    if (['F', 'W1', 'HAC'].includes(indicator.degree)) {
      return "#FFFFFF";
    }
  }

  if (indicator.reviewed) {
    if (['F', 'HAC'].includes(indicator.degree)) {
      return "#FFFFFF";
    }
    if (['W1', 'W2'].includes(indicator.degree)) {
      return "#000000";
    }
  }

  return "#000000";
}

const degreeFontColor = (indicator: Indicator) => {
  if (indicator.reviewed || isStateThree(indicator)) {
    return "#000000";
  }

  if (!indicator.reviewed) {
    if (['F', 'HAC'].includes(indicator.degree)) {
      return "#FFFFFF";
    }
    if (['W1', 'W2'].includes(indicator.degree)) {
      return "#000000";
    }
  }

  return "#000000";
}

const openReviewEpisode = (episodeId: number, IndicatorType: string) => {
  const indicatorCategory = IndicatorType === 'PICQ' ? 1 : 2
  const url = `/episodes/review_episode?episode_id=${episodeId}&indicator_category_id=${indicatorCategory}`
  window.open(url + '#indicators-heading-section', '_blank')
}

const useNotifications = (initialNotifications: RTINotification[]) => {
  const [notifications, setNotifications] = useState(initialNotifications || [])

  const groupedNotifications = groupNotificationsByEpisode(
    filterNotificationsByToday(notifications)
  )

  const addNotification = (notification: RTINotification) => {
    setNotifications((prevNotifications) => [...prevNotifications, notification])
  }

  // This needs to be modified in the next ticket
  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) => prevNotifications.filter((n: { id: number }) => n.id !== id))
  }

  const getGroupedAndSortedIndicators = (indicators: Indicator[]) => {
    return groupAndSortIndicators(indicators)
  }

  return {
    addNotification,
    borderLeftColor,
    degreeColor,
    degreeFontColor,
    getFontColor,
    getGroupedAndSortedIndicators,
    isStateThree,
    labelColor,
    notifications: filterNotificationsByToday(notifications),
    openReviewEpisode,
    removeNotification,
  }
}

export default useNotifications
