import {optionsManagerService} from "../services/options-manager.service"
import {objectValuesPolyfill} from "../../common/helpers"

export var reportsSelectsComponent = (function () {
  // TODO: Vlad - 1st priority - move all exist selects from all pages to one common component, show them by similar config, run all requests from all handlers from this common component!!
  // TODO: remove common functions from application js, move to helpers!

  var _defaultOption

  var _fillRelatedSelect = {
    coder: function () {
      const urlSearchParams = new URLSearchParams(window.location.search)
      let selectElement = document.getElementById("coder_select")
      let optionNames = [...selectElement.options].map(o => o.value)
      var coder = "All"

      if (urlSearchParams.get("profile_id") != null) {
        if (urlSearchParams.get("all_report") === null) {
          let query_corder_id = urlSearchParams.get("profile_id")
            ? urlSearchParams.get("profile_id")
            : null
          let corder_id = null
          if (query_corder_id != null) {
            $.ajax({
              type: "GET",
              contentType: "application/json",
              url: "/reports/fetch_coder.json",
              data: {profile_id: urlSearchParams.get("profile_id")},
              success: function (result) {
                corder_id = result.coder
              },
              async: false,
            })
            coder =
              corder_id != null
                ? optionNames.includes(corder_id)
                  ? corder_id
                  : "All"
                : "All"
          }
        }

        localStorage.setItem("coder_select", coder)
      }

      optionsManagerService.setActiveSavedOption(
        "coder_select",
        "coder_select",
        coder,
      )
    },
    clinician: function () {
      optionsManagerService.setActiveSavedOption(
        "clinician_select",
        "clinician_select",
        "All",
      )
    },
    coderAndClinician: function () {
      optionsManagerService.setActiveSavedOption(
        "coder_select",
        "coder_select",
        "All",
      )
      optionsManagerService.setActiveSavedOption(
        "clinician_select",
        "clinician_select",
        "All",
      )
    },
    hospital: function () {
      optionsManagerService.setActiveSavedOption(
        "hospital_select",
        "hospital_select",
        "All",
      )
    },
    hospitalGroup: function () {
      optionsManagerService.setActiveSavedOption(
        "hospital_group_select",
        "hospital_group_select",
        "All",
      )
    },
    hospitals: function () {
      optionsManagerService.setActiveSavedOption(
        "hospitals_select",
        "hospitals_select",
        "All",
      )
    },
    hospitalGroups: function () {
      optionsManagerService.setActiveSavedOption(
        "hospital_groups_select",
        "hospital_groups_select",
        "All",
      )
    },
    tag: function () {
      optionsManagerService.setActiveSavedOption(
        "tag_select",
        "tag_select",
        "All",
      )
    },
    diseaseChapters: function () {
      optionsManagerService.setActiveSavedOption(
        "disease_chapters_select",
        "disease_chapters_select",
        "All",
      )
    },
    procedureChapters: function () {
      optionsManagerService.setActiveSavedOption(
        "procedure_chapters_select",
        "procedure_chapters_select",
        "All",
      )
    },
    fund: function () {
      optionsManagerService.setActiveSavedOption(
        "fund_select",
        "fund_select",
        "All",
      )
    },
    los: function () {
      optionsManagerService.setActiveSavedOption(
        "los_select",
        "los_select",
        "All",
      )
    },
    care_type: function () {
      optionsManagerService.setActiveSavedOption(
        "care_type_select",
        "care_type_select",
        "All",
      )
    },
    mdc: function () {
      optionsManagerService.setActiveSavedOption(
        "mdc_select",
        "mdc_select",
        "All",
      )
    },
  }

  var _getOptionsByOptionsConfig = function () {
    const pageName = $("body").attr("class")

    var allSelectsForAdmin = [
      {"By Coder": "coder"},
      {"By Clinician": "clinician"},
      {"By Hospital": "hospital"},
      {"By Hospital Group": "hospitalGroup"},
    ]

    var limitedOptions = [
      {"By Hospital": "hospital"},
      {"By Hospital Group": "hospitalGroup"},
    ]

    const standardOptions = [
      {"By Coder": "coder"},
      {"By Clinician": "clinician"},
    ]

    const hospitalGroupStandard = [
      {"By Coder": "coder"},
      {"By Clinician": "clinician"},
      {"By Hospital": "hospital"},
    ]

    const standardStatlHealth = [
      {"By Coder": "coder"},
      {"By Hospital": "hospital"},
      {"By Hospital Group": "hospitalGroup"},
    ]

    const hospitalGroup = [{"By Hospital": "hospital"}]

    var optionsConfig = {
      hospital: {
        standard: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : standardOptions,
        standard_summary_only: pageName.includes(
          "min_max_drg" && "indicator_management",
        )
          ? limitedOptions
          : standardOptions,
        sales: pageName.includes("min_max_drg")
          ? limitedOptions
          : standardOptions,
        coder: [],
        picq_admin: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : allSelectsForAdmin,
      },

      hospital_group: {
        standard: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : hospitalGroupStandard,
        standard_summary_only: pageName.includes(
          "min_max_drg" && "indicator_management",
        )
          ? limitedOptions
          : hospitalGroup,
        sales: pageName.includes("min_max_drg")
          ? limitedOptions
          : hospitalGroup,
        coder: pageName.includes("min_max_drg")
          ? limitedOptions
          : hospitalGroup,
        picq_admin: pageName.includes(
          "reports min_max_drg" && "indicator_management",
        )
          ? limitedOptions
          : allSelectsForAdmin,
      },

      local_health_district: {
        standard: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : hospitalGroupStandard,
        standard_summary_only: pageName.includes(
          "min_max_drg" && "indicator_management",
        )
          ? limitedOptions
          : hospitalGroupStandard,
        sales: pageName.includes("min_max_drg")
          ? limitedOptions
          : hospitalGroupStandard,
        coder: [{"By Hospital": "hospital"}],
        picq_admin: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : allSelectsForAdmin,
      },

      state_health_department: {
        standard: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : standardStatlHealth,
        standard_summary_only: pageName.includes(
          "min_max_drg" && "indicator_management",
        )
          ? limitedOptions
          : hospitalGroup,
        sales: pageName.includes("min_max_drg")
          ? limitedOptions
          : hospitalGroup,
        coder: [],
        picq_admin: pageName.includes("min_max_drg" && "indicator_management")
          ? limitedOptions
          : allSelectsForAdmin,
      },
    }

    var similarRolesConfig = {
      organisation_admin: "standard",
      clinician: "standard",
      clinician_manager: "standard",
      site_admin: "picq_admin",
    }

    var organisationName = $("[data-organisation-type]").data(
      "organisationType",
    )

    var userRole = $("[data-user-role]").data("userRole")

    userRole =
      similarRolesConfig[userRole] !== undefined
        ? similarRolesConfig[userRole]
        : userRole

    // TODO: add validation, cover roles config via unit test;
    _defaultOption = objectValuesPolyfill(
      optionsConfig[organisationName][userRole][0],
    )[0]
    return optionsConfig[organisationName][userRole]
  }

  var _fillReportTypeSelect = function () {
    var reportTypeOptions = _getOptionsByOptionsConfig()

    for (var elem in reportTypeOptions) {
      $("#report_type_select").append(
        '<option value="' +
          objectValuesPolyfill(reportTypeOptions[elem])[0] +
          '">' +
          Object.keys(reportTypeOptions[elem])[0] +
          "</option>",
      )
    }

    optionsManagerService.setActiveSavedOption(
      "report_type_select",
      "report_type_select",
      _defaultOption,
    )
  }

  var _showSelectsByReportType = function () {
    var $reportTypeSelect = $("#report_type_select")
    var reportTypeSelectVal = $reportTypeSelect.val()

    $(
      ".coder, .clinician, .hospital, .hospitals, .hospitalGroup, .hospitalGroups, #reportTypeSelectParent",
    ).hide()

    if (reportTypeSelectVal) {
      _fillRelatedSelect[reportTypeSelectVal]()
      $("#reportTypeSelectParent").show()
      $("." + $reportTypeSelect.val()).show()
    }

    if (reportTypeSelectVal === "hospitals") {
      $("." + $("#report_type_select").val()).show()
    }
  }

  var _showSelectsByExtractName = function () {
    let tagSelect = $("#tag_select")
    let tagSelectVal = tagSelect.val()

    if (tagSelectVal) {
      _fillRelatedSelect["tag"]()
    }
  }

  var _initEventHandlers = function () {
    var $reportSelects = $(
      "#report_type_select, #coder_select, #indicator_degrees_select, #risq_indicator_degrees_select, #risq_hac_categories_select, #risq_hac_indicators_select, #clinician_select, #hospital_select, #hospitals_select, #hospital_groups_select, #tag_select, #hospital_group_select, #disease_chapters_select, #procedure_chapters_select",
    )

    $reportSelects.on("focusin", function (e) {
      var targetId = e.currentTarget.id
      optionsManagerService.selectsStateStorage[targetId].oldValue =
        e.currentTarget.value
    })

    $reportSelects.on("change", function (e) {
      var targetId = e.currentTarget.id

      if (
        optionsManagerService.selectsStateStorage[targetId].oldValue !==
        e.currentTarget.value
      ) {
        optionsManagerService.selectsStateStorage[targetId].newValue =
          e.currentTarget.value
        optionsManagerService.selectsStateStorage[
          targetId
        ].optionIsChanged = true
      }

      if (optionsManagerService.checkOptionIsChanged(targetId)) {
        optionsManagerService.processSavingChangedOption(
          targetId,
          optionsManagerService.selectsStateStorage[targetId].newValue,
        )
      }

      _showSelectsByReportType()
    })
  }

  var initReportsDependentDropdowns = function () {
    var $reportTypeSelect = $("#report_type_select")
    $reportTypeSelect.dropdown()

    _fillReportTypeSelect()
    _showSelectsByReportType()
    _showSelectsByExtractName()

    $("#care_type_select").dropdown()
    $("#clinician_select").dropdown()
    $("#coder_select").dropdown()
    $("#fund_select").dropdown()
    $("#hospital_group_select").dropdown()
    $("#hospital_groups_select").dropdown()
    $("#hospital_select").dropdown()
    $("#hospitals_select").dropdown()
    $("#los_select").dropdown()
    $("#mdc_select").dropdown()
    $("#tag_select").dropdown()

    // TODO: move to disease chapters component
    $("#disease_chapters_select").dropdown()
    $("#procedure_chapters_select").dropdown()
    _fillRelatedSelect["diseaseChapters"]()
    _fillRelatedSelect["procedureChapters"]()

    _initEventHandlers()
  }

  return {
    initReportsDependentDropdowns: initReportsDependentDropdowns,
  }
})()
