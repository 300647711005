// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import {createConsumer} from "@rails/actioncable"

const protocol = window.location.protocol === "https:" ? "wss://" : "ws://"
const websocketUrl = `${protocol}${window.location.host}/cable`

const consumer = createConsumer(websocketUrl)

export default consumer
